import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
//import store from '@/store'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard/all',
    children: [
      {
        path: '/main',
        name: 'Main',
        component: () => import('@/views/Main.vue'),
      },
      {
        path: '/dashboard/:stype',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: '/devices',
        name: 'Devices',
        redirect: '/devices/gateway',
      },
      {
        path: '/devices/gateway',
        name: 'Gateway',
        component: () => import('@/views/devices/Gateway.vue'),
      },
      {
        path: '/devices/zone',
        name: 'Zone',
        component: () => import('@/views/devices/Zone.vue'),
      },
      {
        path: '/devices/controller',
        name: 'Controller',
        component: () => import('@/views/devices/Controller.vue'),
      },
      {
        path: '/devices/lamp',
        name: 'Lamp',
        component: () => import('@/views/devices/Lamp.vue'),
      },
      {
        path: '/devices/group',
        name: 'GroupSet',
        component: () => import('@/views/devices/GroupSet.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/control',
        name: 'Control',
        redirect: '/control/single',
      },
      {
        path: '/control/single',
        name: 'Single',
        component: () => import('@/views/control/Single.vue'),
      },
      {
        path: '/control/group',
        name: 'Group',
        component: () => import('@/views/control/Group.vue'),
      },
      {
        path: '/control/timing',
        name: 'Timing',
        component: () => import('@/views/control/Timing.vue'),
      },
      {
        path: '/control/plc',
        name: 'Plc',
        component: () => import('@/views/control/PlcRelay.vue'),
      },
      {
        path: '/maintain',
        name: 'Maintain',
        redirect: '/maintain/single',
      },
      {
        path: '/maintain/repair',
        name: 'Repair',
        component: () => import('@/views/maintain/Repair.vue'),
      },
      {
        path: '/maintain/staff',
        name: 'Staff',
        component: () => import('@/views/maintain/Staff.vue'),
      },
      {
        path: '/maintain/dispatch',
        name: 'Dispatch',
        component: () => import('@/views/maintain/Dispatch.vue'),
      },
      {
        path: '/notify',
        name: 'Notify',
        component: () => import('@/views/notify/Notify.vue'),
      },
      {
        path: '/chart/connectionRate',
        name: 'ConnectionRate',
        component: () => import('@/views/charts/ConnectionRate.vue'),
      },
      {
        path: '/chart/connectionRateDate',
        name: 'ConnectionRateDate',
        component: () => import('@/views/charts/ConnectionRateDate.vue'),
      },
      {
        path: '/chart/consumption',
        name: 'Consumption',
        component: () => import('@/views/charts/Consumption.vue'),
      },
      {
        path: '/chart/quality',
        name: 'Quality',
        component: () => import('@/views/charts/Quality.vue'),
      },
      {
        path: '/chart/abnormal',
        name: 'Abnormal',
        component: () => import('@/views/charts/Abnormal.vue'),
      },
      {
        path: '/chart/frequency',
        name: 'Frequency',
        component: () => import('@/views/charts/Frequency.vue'),
      },
      {
        path: '/chart/time-limit',
        name: 'TimeLimit',
        component: () => import('@/views/charts/TimeLimit.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/lognotify',
        name: 'LogNotify',
        component: () => import('@/views/record/LogNotify.vue'),
      },
      {
        path: '/record/dashboard',
        name: 'RecordDashboard',
        component: () => import('@/views/record/Dashboard.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/malfunction',
        name: 'Malfunction',
        component: () => import('@/views/record/Malfunction.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/offlineFreq',
        name: 'OfflineFreq',
        component: () => import('@/views/record/OfflineFreq.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/connectionException',
        name: 'connectionException',
        component: () => import('@/views/record/ConnectionException.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/loadAnomaly',
        name: 'loadAnomaly',
        component: () => import('@/views/record/LoadAnomaly.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/operation',
        name: 'Operation',
        component: () => import('@/views/record/Operation.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/electricity',
        name: 'Electricity',
        component: () => import('@/views/record/Electricity.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/record/signinout',
        name: 'SignInOut',
        component: () => import('@/views/record/SignInOut.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/system/data-maintain',
        name: 'DataMaintain',
        component: () => import('@/views/system/DataMaintain.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/system/customer',
        name: 'Customer',
        component: () => import('@/views/system/Customer.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/system/user',
        name: 'User',
        component: () => import('@/views/system/User.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },

      {
        path: '/system/setting',
        name: 'Setting',
        component: () => import('@/views/system/Setting.vue'),
        meta: { requiresAuth: true, requiresSystem: true, requiresAdmin: true },
      },
      {
        path: '/test',
        name: 'Test',
        component: () => import('@/views/test.vue'),
      },
    ],
  },
  {
    path: '/',
    redirect: '/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        component: () => import('@/views/pages/Page404'),
      },
    ],
  },
  {
    path: '/',
    redirect: '/repair/main',
    name: 'Mobile',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/repair/main',
        name: 'RepairMain',
        component: () => import('@/views/mobile/RepairMain'),
      },
      {
        path: '/repair/sub',
        name: 'RepairSub',
        component: () => import('@/views/mobile/RepairMain'),
      },
      {
        path: 'applogin',
        name: 'AppLogin',
        component: () => import('@/views/mobile/AppLogin'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        component: () => import('@/views/pages/Page404'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //history: createWebHistory('/Coreui/'),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

//跳轉權限檢查
router.beforeEach((to, from, next) => {
  //是否需要登入
  if (to.meta.requiresAuth) {
    //使用者是否已經登入
    const isLoggedIn = checkUserLoggedIn()
    //console.log('isLoggedIn:' + isLoggedIn)
    if (isLoggedIn) {
      //是否需要管理權限
      if (to.meta.requiresAdmin) {
        const isAdmin = checkUserAdmin()

        if (isAdmin) {
          next()
        } else {
          // 沒有管理權限轉向到首頁或錯誤訊息頁面
          next('/dashboard')
          // 或者顯示錯誤訊息
          // console.log('You do not have permission to access this page.');
        }
      } else if (to.meta.requiresSystem) {
        const isSystem = checkUserSystem()

        if (isSystem) {
          next()
        } else {
          // 沒有管理權限轉向到首頁或錯誤訊息頁面
          next('/dashboard')
          // 或者顯示錯誤訊息
          // console.log('You do not have permission to access this page.');
        }
      } else {
        next()
      }
    } else {
      //使用者未登入 轉向到登入頁面
      next('/login')
    }
  } else {
    next()
  }
})

// 檢查使用者是否已經登入
function checkUserLoggedIn() {
  // 实际场景中，根据你的认证逻辑进行判断
  // 返回 true 或 false
  //console.log('route state:' + JSON.stringify(store.state))
  //return store.state.isLoggedIn
  return true
}

// 檢查使用者是否為系統管理員
function checkUserSystem() {
  // 实际场景中，根据用户角色或权限进行判断
  // 返回 true 或 false
  return true
}

// 檢查使用者是否為管理員
function checkUserAdmin() {
  // 实际场景中，根据用户角色或权限进行判断
  // 返回 true 或 false
  return true
}

export default router
