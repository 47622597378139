import {
  SET_GATEWAY,
  SET_GATEWAY_TOTAL,
  SET_LOADING,
  SET_GATEWAY_ALL,
} from './mutations'
import axios from 'axios'

export default {
  async fetchGateway({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/gateway/list',
      { params: params },
    )
    commit(SET_GATEWAY, response?.data.value ?? [])
    commit(SET_GATEWAY_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async fetchGatewayAll({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/gateway/list',
      { params: params },
    )
    commit(SET_GATEWAY_ALL, response?.data.value ?? [])
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createGateway({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/gateway/add',
      { params },
    )
    commit(SET_GATEWAY, response?.data.value ?? [])
    commit(SET_GATEWAY_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
  },
}
