import {
  defineComponent,
  h,
  onMounted,
  computed,
  ref,
  resolveComponent,
} from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue'
const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  props: {
    t: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const route = useRoute()
    const firstRender = ref(true)
    const nav = [
      {
        component: 'CNavItem',
        name: '地圖監控中心',
        i18n: 'menu.dashboard',
        to: '/dashboard/all',
        code: 'Dashboard',
        icon: 'cil-puzzle',
      },
      {
        component: 'CNavGroup',
        name: '設備管理',
        i18n: 'menu.devices.title',
        to: '/devices',
        icon: 'cil-devices',
        items: [
          {
            component: 'CNavItem',
            name: '區域',
            i18n: 'menu.devices.zone',
            to: '/devices/zone',
            code: 'Zone',
          },
          {
            component: 'CNavItem',
            name: '閘道器',
            i18n: 'menu.devices.gateway',
            to: '/devices/gateway',
            code: 'Gateway',
          },
          {
            component: 'CNavItem',
            name: '燈控器',
            i18n: 'menu.devices.controller',
            to: '/devices/controller',
            code: 'Controller',
          },
          {
            component: 'CNavItem',
            name: '燈具',
            i18n: 'menu.devices.lamp',
            to: '/devices/lamp',
            code: 'Lamp',
          },
          {
            component: 'CNavItem',
            name: '群組設定',
            i18n: 'menu.devices.group',
            to: '/devices/group',
            code: 'Group',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: '控制',
        i18n: 'menu.control.title',
        to: '/control',
        icon: 'cil-notes',
        items: [
          {
            component: 'CNavItem',
            name: '單燈控制',
            i18n: 'menu.control.single',
            to: '/control/single',
            code: 'Single',
          },
          {
            component: 'CNavItem',
            name: '群組控制',
            i18n: 'menu.control.group',
            to: '/control/group',
            code: 'Group',
          },
          {
            component: 'CNavItem',
            name: '情境控制',
            i18n: 'menu.control.situationalControl',
            to: '/control/situational',
            code: 'Situational',
          },
          {
            component: 'CNavItem',
            name: '調光排程控制',
            i18n: 'menu.control.timing',
            to: '/control/timing',
            code: 'Timing',
          },
          {
            component: 'CNavItem',
            name: 'PLC Relay 排程控制',
            i18n: 'menu.control.plc',
            to: '/control/plc',
            code: 'PlcRelay',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: '訊息通知',
        i18n: 'menu.notify',
        to: '/notify',
        code: 'Notify',
        icon: 'cil-send',
      },
      {
        component: 'CNavGroup',
        name: '維修管理',
        i18n: 'menu.maintain.title',
        to: '/maintain',
        icon: 'cil-star',
        items: [
          {
            component: 'CNavItem',
            name: '故障待修',
            i18n: 'menu.maintain.repair',
            to: '/maintain/repair',
            code: 'Repair',
          },
          {
            component: 'CNavItem',
            name: '維修人員',
            i18n: 'menu.maintain.staff',
            to: '/maintain/staff',
            code: 'Staff',
          },
          {
            component: 'CNavItem',
            name: '派工管理',
            i18n: 'menu.maintain.dispatch',
            to: '/maintain/dispatch',
            code: 'Dispatch',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: '統計',
        i18n: 'menu.chart.title',
        to: '/chart',
        icon: 'cil-bell',
        items: [
          {
            component: 'CNavItem',
            name: '時刻連線分析',
            i18n: 'menu.chart.connectionRate',
            to: '/chart/connectionRate',
            code: 'ConnectionRate',
          },
          {
            component: 'CNavItem',
            name: '區間連線率圖',
            i18n: 'menu.chart.connectionRateDate',
            to: '/chart/connectionRateDate',
            code: 'ConnectionRateDate',
          },
          {
            component: 'CNavItem',
            name: '用電量統計',
            i18n: 'menu.chart.consumption',
            to: '/chart/consumption',
            code: 'Consumption',
          },
          {
            component: 'CNavItem',
            name: '電力品質',
            i18n: 'menu.chart.quality',
            to: '/chart/quality',
            code: 'Quality',
          },
          {
            component: 'CNavItem',
            name: '異常統計',
            i18n: 'menu.chart.abnormal',
            to: '/chart/abnormal',
            code: 'Abnormal',
          },
          {
            component: 'CNavItem',
            name: '故障頻率',
            i18n: 'menu.chart.frequency',
            to: '/chart/frequency',
            code: 'Frequency',
          },
          {
            component: 'CNavItem',
            name: '維修時效',
            i18n: 'menu.chart.time',
            to: '/chart/time-limit',
            code: 'TimeLimit',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: '記錄查詢',
        i18n: 'menu.record.title',
        to: '/record',
        icon: 'cil-chart-pie',
        items: [
          {
            component: 'CNavItem',
            name: '系統運行彙總表',
            i18n: 'menu.record.dashboard',
            to: '/record/dashboard',
            code: 'DashboardReport',
          },
          {
            component: 'CNavItem',
            name: '錯誤/警告',
            i18n: 'menu.record.malfunction',
            to: '/record/malfunction',
            code: 'Malfunction',
          },
          {
            component: 'CNavItem',
            name: '離線頻率',
            i18n: 'menu.record.offlineFreq',
            to: '/record/offlineFreq',
            code: 'OfflineFreq',
          },
          {
            component: 'CNavItem',
            name: '連線異常記錄',
            i18n: 'menu.record.connectionException',
            to: '/record/connectionException',
            code: 'ConnectionException',
          },
          {
            component: 'CNavItem',
            name: '負載異常分析',
            i18n: 'menu.record.loadAnomaly',
            to: '/record/loadAnomaly',
            code: 'LoadAnomaly',
          },
          {
            component: 'CNavItem',
            name: '電力資訊',
            i18n: 'menu.record.electricity',
            to: '/record/electricity',
            code: 'Electricity',
          },
          {
            component: 'CNavItem',
            name: '通知歷史',
            i18n: 'menu.record.lognotify',
            to: '/record/lognotify',
            code: 'Lognotify',
          },
          {
            component: 'CNavItem',
            name: '控制操作',
            i18n: 'menu.record.operation',
            to: '/record/operation',
            code: 'Operation',
          },
          {
            component: 'CNavItem',
            name: '系統登錄',
            i18n: 'menu.record.signinout',
            to: '/record/signinout',
            code: 'Signinout',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: '系統管理',
        i18n: 'menu.system.title',
        to: '/system',
        icon: 'cil-settings',
        items: [
          {
            component: 'CNavItem',
            name: '資料維護',
            i18n: 'menu.system.maintain',
            to: '/system/data-maintain',
            code: 'DataMaintain',
          },
          {
            component: 'CNavItem',
            name: '帳號管理',
            i18n: 'menu.system.user',
            to: '/system/user',
            code: 'User',
          },
          {
            component: 'CNavItem',
            name: '系統設定',
            i18n: 'menu.system.setting',
            to: '/system/setting',
            code: 'Setting',
          },
        ],
      },
    ]
    onMounted(() => {
      firstRender.value = false
    })
    let permission = localStorage.getItem('Permission')
    let usePermission = permission ? permission?.split(',') : []
    // console.log(usePermission)
    const filteredNav = computed(() => {
      const newNav = []
      Object.assign(newNav, nav)
      return newNav.filter((item) => {
        if (item.code) {
          return usePermission.includes(item.code)
        } else if (item.items) {
          item.items = item.items.filter((subItem) => {
            return usePermission.includes(subItem.code)
          })
          return item.items.length > 0
        }
        return true
      })
    })

    const normalizePath = (path) =>
      decodeURI(path)
        .replace(/#.*$/, '')
        .replace(/(index)?\.(html)$/, '')

    const isActiveLink = (route, link) => {
      if (link === undefined) {
        return false
      }

      if (route.hash === link) {
        return true
      }

      const currentPath = normalizePath(route.path)
      const targetPath = normalizePath(link)

      return currentPath === targetPath
    }

    const isActiveItem = (route, item) => {
      if (isActiveLink(route, item.to)) {
        return true
      }

      if (item.items) {
        return item.items.some((child) => isActiveItem(route, child))
      }

      return false
    }

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon',
                name: item.icon,
              }),
              props.t(item.i18n),
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (prop) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: prop.isActive,
                    href: prop.href,
                    onClick: () => prop.navigate(),
                  },
                  {
                    default: () => [
                      item.icon &&
                        h(resolveComponent('CIcon'), {
                          customClassName: 'nav-icon',
                          name: item.icon,
                        }),
                      props.t(item.i18n),
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: 'ms-auto',
                            color: item.badge.color,
                          },
                          {
                            default: () => item.badge.text,
                          },
                        ),
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => props.t(item.name),
            },
          )
    }

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => filteredNav.value.map((item) => renderItem(item)),
        },
      )
  },
})
export { AppSidebarNav }
