import {
  SET_DASHBOARD_TOTAL,
  SET_DASHBOARD,
  SET_LOADING,
  SET_PAGE,
} from './mutations'
import axios from 'axios'

export default {
  async fetchDashboard({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/dashboard/list',
      { params: params },
    )
    commit(SET_DASHBOARD, response?.data.value ?? [])
    commit(SET_DASHBOARD_TOTAL, response?.data?.total_count ?? 0)
    // console.log('response?.data?.total_count', response?.data?.total_count)
    commit(SET_PAGE, response?.page ?? 1)

    commit(SET_LOADING, false)
    return response
  },
}
