import { SET_CONTROLLER, SET_CONTROLLER_TOTAL, SET_LOADING } from './mutations'
import axios from 'axios'

export default {
  async fetchController({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.get(
      process.env.VUE_APP_BASEURL + '/def_controller/list',
      { params: params },
    )
    commit(SET_CONTROLLER, response?.data.value ?? [])
    commit(SET_CONTROLLER_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
    return response?.data.value || []
  },
  async createController({ commit }, params = {}) {
    commit(SET_LOADING, true)
    const response = await axios.post(
      process.env.VUE_APP_BASEURL + '/controller',
      { params },
    )
    commit(SET_CONTROLLER, response?.data.value ?? [])
    commit(SET_CONTROLLER_TOTAL, response?.data?.total_count ?? 0)
    commit(SET_LOADING, false)
  },
}
